import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { JwtService } from './jwtservice';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(private route: Router, private jwtservice: JwtService) { }

  /**
   *
   * @returns Login User Token Get
   */
  loggedIn() {
    //Check if user Token and userId exists or not. if token not available tredirect him to login
    this.isLoggedIn.next(true);
    let hasToken = false;
    if (localStorage.getItem('mbcrm/token') && localStorage.getItem('userId')) {
      hasToken = true;
    } else {
      this.route.navigate(['/login'])
    }
    return hasToken;
  }


  get isLoggedIn$() {
    return this.isLoggedIn.asObservable();
  }

  // /**
  //  *
  //  * @returns Local Storage Token Get
  //  */
  // getToken() {
  //   return localStorage.getItem('token');
  // }

  /**
   * Gets the decoded token of user. It will be used tor ead permissions
   */
  getDecodedToken() {

  }

  /**
   * Logout User
   */
  logout(): void {
    localStorage.clear();
    this.route.navigate(['/login']);
  }
}

