import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class JwtService {
    decodedToken: any
    jwtHelper = new JwtHelperService()
    constructor() {

    }

    decodeToken(token: any) {
        this.decodedToken = this.jwtHelper.decodeToken(token);
        return this.decodedToken;
    }
}