import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DeepVueCredentials } from './documentverification/documentverificationendpoints';
import { ToastrService } from 'ngx-toastr';

let documentVerificationToken = localStorage.getItem(
  'mbcrm/docVerificationToken'
);

let docHeaders = new HttpHeaders()
  .set('Content-Type', 'application/x-www-form-urlencoded')
  .set('Access-Control-Allow-Origin', '*')

let headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')

@Injectable({
  providedIn: 'root'
})
export class RestService {

  // public localStorageSiteId: any = localStorage.getItem('siteId');
  // intercept(req:HttpRequest<any>, next:Http.pipe(catchError(error =>this.handleEror(error)))r){
  //   let modifiedRequest = req.clone({
  //     headers: req.headers.append('auth','abc')
  //   });
  //   return next.handle(modifiedRequest);
  // }
  apiUrl = environment.apiEndpoint + '/api/';

  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  private handleError(error: HttpErrorResponse, errorMessages?: string) {
    let commonErrorMessage = 'Unknown error occurred.';
    this.toastr.error(errorMessages ? errorMessages : commonErrorMessage);
    return throwError(errorMessages ? errorMessages : commonErrorMessage);
  }


  /**
   * Gets Data from API based on API Url endpoints
   * @param urlName API URL from where data has to be fethed
   * @param isDocVerifyReq Checks if current request is for document verification API
   * @returns response from API
   */
  getData(urlName: string, isDocVerifyReq?: boolean, errorMessages?: string): Observable<any> {
    if (isDocVerifyReq) {
      //If current api is called foer document verification then check from Doc Verification API
      //Else to complete request from our own API then check data source API/Dymmy
      docHeaders = docHeaders.append('Authorization', `Bearer ${documentVerificationToken}`);
      docHeaders = docHeaders.append('x-api-key', DeepVueCredentials.client_secret);

      return this.http.get<any>(`${this.apiUrl}${urlName}`, { headers: docHeaders })
        .pipe(catchError(error => this.handleError(error, errorMessages)));
    }
    else {
      if (environment.isDummyDataSource) {
        return this.http.get<any>(`${urlName}`)
      }
      else {
        return this.http.get<any>(`${this.apiUrl}${urlName}`, { headers: headers });
      }
    }
  }

  /**
   *api control for post operation
   * @param urlName : Url to which request has to be sent
   * @param data : Data need to be posted in api request
   * @param isDocVerifyReq: Checks if this request is for document verification API
   * @returns Response returned from API Req
   */
  postData(urlName: string, data: any, isDocVerifyReq?: boolean): Observable<any> {
    if (isDocVerifyReq) {
      return this.http.post<any>(`${urlName}`, data, { headers: docHeaders });
    } else {
      return this.http.post<any>(`${this.apiUrl}${urlName}`, data, { headers: headers });
    }
  }


  postFormData(urlName: string, data: FormData, isDocVerifyReq?: boolean): Observable<any> {
    if (isDocVerifyReq) {
      return this.http.post<any>(`${urlName}`, data, { headers: docHeaders });
    } else {
      return this.http.post<any>(`${this.apiUrl}${urlName}`, data);
    }
  }

  putFormData(urlName: string, data: FormData): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${urlName}`, data);
  }

  patchData(urlName: string, data: any): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}${urlName}`, data);
  }
  // PUT request example
  /**
   * api control for put operation
   * @param urlName Url to which request has to be sent
   * @param id record id whose data has to be updated
   * @param data Data need to be posted in api request
   * @param isDocVerifyReq: Checks if this request is for document verification API
   * @returns Response returned from API Req
   */
  updateData(urlName: string, id: number, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${urlName}/${id}`, data,
      { headers: headers });
  }

  updateDataWithoutId(urlName: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${urlName}`, data,
      { headers: headers });
  }

  // DELETE request example
  /**
   * api control for delete operation
   * @param urlName
   * @param id
   * @returns
   */
  deleteData(urlName: string, id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${urlName}/${id}`,
      { headers: headers });
  }

  /**
   * Search Bank details based on IFCC Code
   * @param ifscCode IFSC Code for which data has to be searched
   * @returns returns response returned from API
   */
  getBankDetailsByifsc(ifscCode: any): Observable<any> {
    return this.http.get(`${this.apiUrl+"bank-master/SearchIfsc?ifsc="}${ifscCode}`,
      { headers: docHeaders }
    );
  }
}
